<template>
<div>
    <div class="login-content"></div>
    <div class="centred-by-screen zz">{{$t('404')}}</div>
</div>
</template>

<script>

export default ({
    // data() {
    //     return {
    //     }
    // }
})
</script>


<style scoped>
.login-content {
    background-image: url("../assets/img/login/bg.jpg");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100%;
    /* background-size: cover; */
    position: fixed;
    left: 0;
    top: 0;
    min-width: 1800px;
    min-height: 900px; 
    width: 100vw;
    height: 100vh;
}
.zz {
    margin-left: -260px;
    font-size: 50px;
    font-weight: 300;
}
</style>